import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { MenuItem, MessageService } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { constants, UserRoles } from '../../../shared/constants/constants';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Reviewer, Tenant } from '../../../store/reviewer/reviewer.interface';
import { StorageService } from '../../../shared/services/storage.service';
import { Store } from '@ngrx/store';
import { BadgeModule } from 'primeng/badge';
import { EmittersService } from '../../../shared/services/emitters.service';
import { FirebaseMessagingService } from '../../../shared/services/firebase-messaging.service';
import { SkillAndCredentialService } from '../../../shared/services/skill-credential.service';
import { MessagingCountHash } from '../../client/dashboard/message/message.interface';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import {
  getSessionFirmAddress,
  getSessionFirmName,
} from '../../../store/user/user.selector';
import { DocumentService } from '../../../shared/services/document-service';
@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    RippleModule,
    PanelMenuModule,
    CommonModule,
    BadgeModule,
    AvatarModule,
    AvatarGroupModule,
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  providers: [MessageService, UserService],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() from: string = 'dashboard';
  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter<void>();
  items: MenuItem[] = [];
  additionalItems: MenuItem[] = [];
  countSubscriber: any = [];
  private subscription: Subscription = new Subscription();
  userRole: string = '';
  unreadCountInterval: any;
  firmAddress$: Observable<string>;
  firmName$: Observable<string>;
  firmLogo!: string;

  onboardingItems = [
    {
      label: 'Onboarding',
      icon: 'space_dashboard',
      role: [UserRoles.reviewer],
    },
  ];
  mailToContact: string = 'info@mplace.io';
  selectedTab = 'Dashboard';
  user: any = null;
  credentials = [];
  skillsAndExperience = [];
  reviewer!: Reviewer;
  messageCount: number = 0;
  selectedTenant: any = null;
  tenantEmitterSubscription!: Subscription;
  skillsAndExperienceSubscription!: Subscription;
  credentialEmitterSubscription!: Subscription;
  isCurrentlyAssigned: boolean = true;
  isCurrentlyAssignedSubscription!: Subscription;
  constructor(
    private router: Router,
    private userService: UserService,
    private reviewerService: ReviewerService,
    private authService: AuthService,
    private documentService: DocumentService,
    private storageService: StorageService,
    private store: Store,
    private eventEmitterService: EmittersService,
    private firebaseMessagingService: FirebaseMessagingService,
    public skillAndCredentialService: SkillAndCredentialService
  ) {
    this.firmAddress$ = this.store.select(getSessionFirmAddress);
    this.firmName$ = this.store.select(getSessionFirmName);
    this.user = this.userService.getSessionUser();
    this.userRole = this.storageService.getCookieValueByKey('role');

    this.firebaseMessagingService.initFirebase();
  }

  ngOnInit() {
    this.reviewer = this.reviewerService.getReviewer();
    this.firmLogo =
      this.documentService.getDocumentPathById(this.user.firm.logo || '') || '';
    if (Object.keys(this.reviewer).length) {
      if(this.userRole === 'REVIEWER'){
        this.getPositions();}
    }
    if (this.from === 'dashboard') {
      this.initializeItems();
    } else {
      this.items = this.onboardingItems;
    }

    this.initializeUnreadMessageCount();

    if (UserRoles.reviewer === this.userRole) {
      this.initializeCredentialAndSkills();
      this.subscribeEvents();
      this.selectedTenant = this.storageService.getTenant();
    }
  }

  sideNavToggle(): void {
    this.toggleSideNav.emit();
  }
  initializeUnreadMessageCount() {
    if (this.userRole !== constants.userRoles.reviewer) {
      this.getUnreadMessageCount();
      this.getReviewerUnreadMessageCountByFirm();
    } else {
      this.getReviewerUnreadMessageCount();
    }
  }

  reEvaluateReviewerUnreadCount() {
    this.countSubscriber.forEach((o: any) => {
      o.unsubscribe();
    });

    this.getReviewerUnreadMessageCount();
  }

  subscribeEvents() {
    this.tenantEmitterSubscription =
      this.eventEmitterService.tenantEmitter.subscribe(async () => {
        this.initializeCredentialAndSkills();
        this.reEvaluateReviewerUnreadCount();
        this.selectedTenant = this.storageService.getTenant();
      });

    this.skillsAndExperienceSubscription =
      this.eventEmitterService.skillAndExperienceEmitter.subscribe(() => {
        this.getSkillAndExperience();
      });

    this.credentialEmitterSubscription =
      this.eventEmitterService.credentialEmitter.subscribe(() => {
        this.getCredential();
      });

    this.isCurrentlyAssignedSubscription =
      this.eventEmitterService.isCurrentlyAssignedEmitter.subscribe(() => { 
        if(this.userRole === 'REVIEWER'){
          this.getPositions();}
      });
  }

  getPositions(): void {
    this.reviewerService.getPositions().subscribe((res) => {
      this.isCurrentlyAssigned = res.occupied.length > 0 ? true : false;
    });
  }

  initializeCredentialAndSkills() {
    if (this.from === 'dashboard') {
      this.initializeItems();
    }

    this.getSkillAndExperience();
    this.getCredential();
  }

  getSkillAndExperience(): void {
    this.reviewerService
      .getFilteredCriteriaBasedUserSkills(this.reviewer._id)
      .subscribe((skillsAndExperience) => {
        this.skillsAndExperience = skillsAndExperience;
        if (this.from === 'dashboard') {
          this.initializeItems();
        }
        this.skillAndCredentialService.setSkills(true);
        if (!this.skillsAndExperience || !this.skillsAndExperience.length) {
          this.skillAndCredentialService.setSkills(false);
          this.navigateToGeneralIfCurrentPageDataNotFound(
            'reviewer/profile/general',
            '/reviewer/profile/skills'
          );
        }
      });
  }

  getCredential(): void {
    this.reviewerService
      .getFilteredCredentialBasedUserSkills(this.reviewer._id)
      .subscribe((credential) => {
        this.credentials = credential;
        if (this.from === 'dashboard') {
          this.initializeItems();
        }
        this.skillAndCredentialService.setCredentials(true);
        if (!this.credentials || !this.credentials.length) {
          this.skillAndCredentialService.setCredentials(false);
          this.navigateToGeneralIfCurrentPageDataNotFound(
            'reviewer/profile/general',
            '/reviewer/profile/credentials'
          );
        }
      });
  }

  navigateToGeneralIfCurrentPageDataNotFound(
    navigateTo: string,
    currentRoute: string
  ) {
    if (this.router.url === currentRoute) {
      this.goto(navigateTo);
    }
  }

  goto(url: string) {
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.countSubscriber.forEach((o: any) => {
      o.unsubscribe();
    });
    clearInterval(this.unreadCountInterval);
    this.tenantEmitterSubscription &&
      this.tenantEmitterSubscription.unsubscribe();
    this.skillsAndExperienceSubscription &&
      this.skillsAndExperienceSubscription.unsubscribe();
    this.credentialEmitterSubscription &&
      this.credentialEmitterSubscription.unsubscribe();
    this.isCurrentlyAssignedSubscription &&
      this.isCurrentlyAssignedSubscription.unsubscribe();
  }

  initializeItems(): void {
    const currentRoute = this.router.url;

    const profileRoute = {
      label: 'Profile',
      icon: 'person',
      expanded: this.isReviewerProfileRoute(currentRoute),
      items: [
        {
          label: 'General Information',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/general']);
          },
          url: '/reviewer/profile/general',
        },
        {
          label: 'Education',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/education']);
          },
          url: '/reviewer/profile/education',
        },
        {
          label: 'Credentials',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/credentials']);
          },
          url: '/reviewer/profile/credentials',
        },
        {
          label: 'Skills & Experience',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/skills']);
          },
          url: '/reviewer/profile/skills',
        },
        {
          label: 'Resume & Certifications',
          icon: '',
          command: () => {
            this.router.navigate(['/reviewer/profile/resume']);
          },
          url: '/reviewer/profile/resume',
        },
      ],
      role: [UserRoles.reviewer],
    };
    if (!this.credentials || !this.credentials.length) {
      profileRoute.items = profileRoute.items.filter(
        (item) => item.label !== 'Credentials'
      );
    }
    if (!this.skillsAndExperience || !this.skillsAndExperience.length) {
      profileRoute.items = profileRoute.items.filter(
        (item) => item.label !== 'Skills & Experience'
      );
    }

    this.items = [
      {
        label: 'Dashboard',
        icon: 'space_dashboard',
        command: () => {
          this.router.navigate(['/client/overview']);
        },
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
        url: '/client/overview',
      },
      {
        label: 'Project',
        icon: 'folder_open',
        expanded: this.isProjectRoute(currentRoute),
        items: [
          {
            label: 'Started',
            icon: '',
            url: '/client/project/started',
            command: () => {
              this.router.navigate(['/client/project/started']);
            },
          },
          {
            label: 'Published',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/published']);
            },
            url: '/client/project/published',
          },
          {
            label: 'Unpublished',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/unpublished']);
            },
            url: '/client/project/unpublished',
          },
          {
            label: 'Archived',
            icon: '',
            command: () => {
              this.router.navigate(['/client/project/archived']);
            },
            url: '/client/project/archived',
          },
        ],
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'People',
        icon: 'people',
        expanded: this.isPeopleRoute(currentRoute),
        items: [
          {
            label: 'All',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/all']);
            },
            url: '/client/people/all',
          },
          {
            label: 'Worked Together',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/workTogether']);
            },
            url: '/client/people/workTogether',
          },
          {
            label: 'Restricted',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/restricted']);
            },
            url: '/client/people/restricted',
          },
          {
            label: 'Talent Pool',
            icon: '',
            command: () => {
              this.router.navigate(['/client/people/talentPool']);
            },
            url: '/client/people/talentPool',
          },
        ],
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Messages',
        icon: 'chat',
        expanded: this.isSettingsRoute(currentRoute),
        command: () => {
          this.router.navigate(['/client/messaging']);
        },
        url: '/client/messaging',
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Reports',
        icon: 'summarize',
        expanded: this.isReportRoute(currentRoute),
        items: [
          {
            label: 'Payroll',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/payroll']);
            },
            url: '/client/reports/payroll',
          },
          {
            label: 'Invoice',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/invoice']);
            },
            url: '/client/reports/invoice',
          },
          {
            label: 'All working reviewers',
            icon: '',
            command: () => {
              this.router.navigate(['/client/reports/all-working-reviewers']);
            },
            url: '/client/reports/all-working-reviewers',
          },
        ],
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Documents',
        icon: 'lab_profile',
        command: () => {
          this.router.navigate(['/client/documents']);
        },
        url: '/client/documents',
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Firm Users',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/client/firm-users']);
        },
        url: '/client/firm-users',
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Dashboard',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/reviewer/dashboard']);
        },
        role: [
          UserRoles.reviewer || UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
        url: '/reviewer/dashboard',
      },
      profileRoute,
      {
        label: 'Messages',
        icon: 'chat',
        command: () => {
          this.router.navigate(['/reviewer/messages']);
        },
        role: [UserRoles.reviewer],
        url: '/reviewer/messages',
      },
      {
        label:
          this.selectedTenant &&
          this.selectedTenant.firm &&
          this.selectedTenant.firm.isInvoicingEnabled
            ? 'Invoice Generator'
            : 'Timekeeping',
        icon: 'schedule',
        command: () => {
          this.router.navigate(['/reviewer/timekeeping']);
        },
        role: [UserRoles.reviewer],
        url: '/reviewer/timekeeping',
      },

      {
        label: 'Past Projects',
        icon: 'verified_user',
        command: () => {
          this.router.navigate(['/reviewer/past-projects']);
        },
        role: [UserRoles.reviewer],
        url: '/reviewer/past-projects',
      },
      {
        label: 'Settings',
        icon: 'settings',
        command: () => {
          this.router.navigate(['/reviewer/settings']);
        },
        url: '/reviewer/settings',
        role: [UserRoles.reviewer],
      },
    ];
    this.additionalItems = [
      {
        label: 'Settings',
        icon: 'settings',
        expanded: this.isSettingsRoute(currentRoute),
        items: [
          {
            label: 'Job Types',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/job-types']);
            },
            url: '/client/settings/job-types',
          },
          {
            label: 'Search Markets',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/search-markets']);
            },
            url: '/client/settings/search-markets',
          },
          {
            label: 'Skills and Experience',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/dynamic-criteria']);
            },
            url: '/client/settings/dynamic-criteria',
          },
          {
            label: 'Credentialing',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/dynamic-credential']);
            },
            url: '/client/settings/dynamic-credential',
          },
          {
            label: 'Change Password',
            icon: '',
            command: () => {
              this.router.navigate(['/client/settings/change-password']);
            },
            url: '/client/settings/change-password',
          },
        ],
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
      {
        label: 'Contact',
        icon: 'contact_support',
        url: `mailto:${this.mailToContact}`,
        role: [
          UserRoles.firmManager,
          UserRoles.projectOwner,
          UserRoles.projectManager,
        ],
      },
    ];

    this.setActiveTab([...this.items, ...this.additionalItems], currentRoute);
  }

  isProjectRoute(currentRoute: string): boolean {
    const projectRoutes = [
      '/client/project/started',
      '/client/project/published',
      '/client/project/unpublished',
      '/client/project/archived',
      '/client/project/create',
      '/client/project/edit',
    ];
    return projectRoutes.some((route) => currentRoute.includes(route));
  }

  isPeopleRoute(currentRoute: string): boolean {
    const peopleRoutes = [
      '/client/people/all',
      '/client/people/pending',
      '/client/people/workTogether',
      '/client/people/restricted',
      '/client/people/talentPool',
      'client/people/invite-candidate',
    ];
    return peopleRoutes.some((route) => currentRoute.includes(route));
  }

  isSettingsRoute(currentRoute: string): boolean {
    const settingsRoutes = [
      '/client/settings/job-type',
      '/client/settings/search-markets',
      '/client/settings/dynamic-criteria',
      '/client/settings/dynamic-credential',
      '/client/settings/change-password',
    ];
    return settingsRoutes.some((route) => currentRoute.includes(route));
  }

  isReportRoute(currentRoute: string): boolean {
    const reportRoutes = [
      '/client/reports/payroll',
      '/client/reports/invoice',
      '/client/reports/all-working-reviewers',
    ];
    return reportRoutes.some((route) => currentRoute.includes(route));
  }

  isReviewerProfileRoute(currentRoute: string): boolean {
    const reviewerProfileRoutes = [
      '/reviewer/profile/general',
      '/reviewer/profile/education',
      '/reviewer/profile/credentials',
      '/reviewer/profile/skills',
      '/reviewer/profile/resume',
      '/reviewer/profile/resume-generator',
    ];
    return reviewerProfileRoutes.some((route) => currentRoute.includes(route));
  }

  setActiveTab(menuItems: Array<MenuItem>, currentRoute: string) {
    menuItems.forEach((item) => {
      if (item.items && item.items.length) {
        this.setActiveTab(item.items, currentRoute);
      } else if (currentRoute.indexOf(item.url || '') > -1) {
        this.setSelectedTab(item.label || '');
      }
    });
  }

  setSelectedTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  getMenuItemsByRole() {
    let items = this.items;
    if (!this.user.firm.isDocusignEnabled) {
      items = this.items.filter((item) => item.label !== 'Documents');
    }
    if (!this.isCurrentlyAssigned) {
      items = this.items.filter(
        (item) =>
          item.label !== 'Messages' &&
          item.label !== 'Invoice Generator' &&
          item.label !== 'Timekeeping'
      );
    }
    return items.filter((i) => {
      return i['role'].includes(this.userRole);
    });
  }

  getAdditionalMenuItemsByRole() {
    return this.additionalItems.filter((i) => {
      return i['role'].includes(this.userRole);
    });
  }

  getUnreadMessageCount() {
    this.countSubscriber.push(
      this.firebaseMessagingService
        .getFirmUnReadMessages()
        .subscribe((unReadDiscussions: any) => {
          let unreadCount = 0;
          const unreadMessageHash: MessagingCountHash = {};

          Object.values(unReadDiscussions).forEach((o: any) => {
            if (unreadMessageHash.hasOwnProperty(o.projectId)) {
              unreadMessageHash[o.projectId] += o.pmUnReadMessageCount;
            } else {
              unreadMessageHash[o.projectId] = o.pmUnReadMessageCount;
            }

            if (
              unreadMessageHash.hasOwnProperty(`${o.projectId}_${o.roleId}`)
            ) {
              unreadMessageHash[`${o.projectId}_${o.roleId}`] +=
                o.pmUnReadMessageCount;
            } else {
              unreadMessageHash[`${o.projectId}_${o.roleId}`] =
                o.pmUnReadMessageCount;
            }

            if (
              unreadMessageHash.hasOwnProperty(
                `${o.projectId}_${o.roleId}_${o.reviewerId}`
              )
            ) {
              unreadMessageHash[`${o.projectId}_${o.roleId}_${o.reviewerId}`] +=
                o.pmUnReadMessageCount;
            } else {
              unreadMessageHash[`${o.projectId}_${o.roleId}_${o.reviewerId}`] =
                o.pmUnReadMessageCount;
            }
            unreadCount += o.pmUnReadMessageCount;
          });

          this.eventEmitterService.messageCountEmitter.next(unreadMessageHash);

          this.messageCount = unreadCount;
        })
    );
  }

  getReviewerUnreadMessageCountByFirm(){
    const firmId = this.userService.getUserFirmId();
    this.firebaseMessagingService
      .getReviewerUnReadMessagesByFirm(firmId)
      .subscribe((unReadDiscussions: any) => {
        const reviewerUnreadMessageHash: MessagingCountHash = {};
        unReadDiscussions.forEach((discussion: any) => {
          if(reviewerUnreadMessageHash.hasOwnProperty(`${discussion.projectId}_${discussion.roleId}_${discussion.reviewerId}`)){
            reviewerUnreadMessageHash[`${discussion.projectId}_${discussion.roleId}_${discussion.reviewerId}`] += discussion.reviewerUnreadMessageCount;
          } else {
            reviewerUnreadMessageHash[`${discussion.projectId}_${discussion.roleId}_${discussion.reviewerId}`] = discussion.reviewerUnreadMessageCount || 0;
          }
        });
        this.eventEmitterService.reviewerMessageFromFirmCountEmitter.next(reviewerUnreadMessageHash);
      }
    )
  }

  getReviewerUnreadMessageCount() {
    this.countSubscriber.push(
      this.firebaseMessagingService
        .getReviewerUnReadMessages()
        .subscribe((unReadDiscussions: any) => {
          let unreadCount = 0;
          let Tenant = this.storageService.getTenant();
          Object.values(unReadDiscussions).forEach((o: any) => {
            if (Tenant.firm.id === o.firmId) {
              unreadCount += o.reviewerUnreadMessageCount;
            }
          });
          this.messageCount = unreadCount;
        })
    );
  }

  breakAddress(address: string, chunkSize: number = 50): string[] {
    if (!address) return [];
    // If the address is longer than 47 characters, truncate and add ellipsis
    const truncatedAddress =
      address.length > 47 ? address.slice(0, 47) + '...' : address;
    return Array.from(
      { length: Math.ceil(truncatedAddress.length / chunkSize) },
      (_, i) => truncatedAddress.slice(i * chunkSize, (i + 1) * chunkSize)
    );
  }
}
