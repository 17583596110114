<div class="sidebar">
    @if(userRole !== "REVIEWER" && userRole !== "ADMIN" && from === 'dashboard'){
    <div class="md:hidden block">
        <div class="flex flex-column p-3">
            <div class="flex gap-2 align-items-center">
                <p-avatar [image]="firmLogo" styleClass="header-avatar" size="large" shape="circle"></p-avatar>
                <h6 class="m-0 text-black-alpha-90">{{firmName$ | async}}</h6>
            </div>
            <p [pTooltip]="(firmAddress$ | async) || 'No address available'" tooltipPosition="top"
                *ngIf="firmAddress$ | async as address" class="text-black-alpha-90">
                <span *ngFor="let chunk of breakAddress(address)">
                    {{ chunk }}
                </span>
            </p>
        </div>
    </div>
    }
    <div class="main-menu">
        <p-panelMenu [model]="getMenuItemsByRole()">
            <ng-template pTemplate="item" let-item>
                <ng-container *ngIf="item.route">
                    <a [routerLink]="item.route" class="flex align-items-center cursor-pointer wrap-single">
                        <span [class]="item.icon"></span>
                        <span class="ml-3">{{ item.label }}</span>
                        @if(item.label === 'Messages' && messageCount > 0){
                        <p-badge class="flex right" value="{{messageCount}}" severity="primary"></p-badge>
                        }
                    </a>
                </ng-container>
                <ng-container *ngIf="item.url">
                    <a (click)="selectedTab = item.label" [class.active]="item?.label === selectedTab"
                        [routerLink]="item.url" class="p-menuitem-link pl-4">
                        <span [class]="item.icon"></span>
                        <span>{{ item.label }} </span>

                    </a>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="headercontent" let-item>
                <div (click)="selectedTab = item.label" [class.active]="item?.label === selectedTab"
                    class="flex align-items-center cursor-pointer wrap-single space-adj">
                    <span class="material-symbols-outlined nav-icon">
                        {{item.icon}}
                    </span>
                    <span class="ml-3">{{ item.label }}</span>
                    @if(item.label === 'Messages' && messageCount > 0){
                    <p-badge class="flex right ml-3" value="{{messageCount}}" severity="primary"></p-badge>
                    }
                </div>
            </ng-template>
        </p-panelMenu>
    </div>
    <div class="additional-menu">
        <p-panelMenu [model]="getAdditionalMenuItemsByRole()">
            <ng-template pTemplate="additionalItems" let-additionalItems>
                <ng-container *ngIf="additionalItems.route">
                    <a [routerLink]="additionalItems.route" class="flex align-items-center cursor-pointer wrap-single">
                        <span [class]="additionalItems.icon"></span>
                        <span class="ml-3">{{ additionalItems.label }}</span>
                    </a>
                </ng-container>
                <ng-container *ngIf="additionalItems.url">
                    <a (click)="selectedTab = additionalItems.label"
                        [class.active]="additionalItems?.label === selectedTab" [routerLink]="additionalItems.url"
                        class="p-menuitem-link pl-4">
                        <span [class]="additionalItems.icon"></span>
                        <span>{{ additionalItems.label }}</span>
                    </a>
                </ng-container>
            </ng-template>

            <ng-template pTemplate="headercontent" let-additionalItems>
                <div (click)="selectedTab = additionalItems.label"
                    [class.active]="additionalItems?.label === selectedTab"
                    class="flex align-items-center cursor-pointer wrap-single space-adj">
                    @if(additionalItems.label === 'Contact')
                    {
                    <a class="p-menuitem-link" style="color: inherit; text-decoration: none;"
                        href="{{additionalItems.url}}" target="_top">
                        <span class="material-symbols-outlined nav-icon">
                            {{additionalItems.icon}}
                        </span>
                        <span class="ml-3">{{ additionalItems.label}}</span>
                    </a>
                    }
                    @if(additionalItems.label !== 'Contact')
                    {
                    <span class="material-symbols-outlined nav-icon">
                        {{additionalItems.icon}}
                    </span>
                    <span class="ml-3">{{ additionalItems.label }}</span>
                    }
                </div>
            </ng-template>
        </p-panelMenu>
    </div>
</div>
<div class="sidebar-backdrop" (click)="sideNavToggle()"></div>